import { project } from "./project"
import { internalNotification } from "./internalNotification"
import { template } from "./template"
import { swProject } from "./swProject"

export const corporationMember = {
  project,
  internalNotification,
  template,
  swProject,
}
