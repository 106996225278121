import { ApiClient } from "~/clApi/main/foundation/ApiClient"

const basePath = (universalCorporationId: string, projectId: string) =>
  `/project/corporation_member/${universalCorporationId}/project/${projectId}/content`

export const write = {
  changeSwProject: (params: {
    universalCorporationId: string
    projectId: string
    swProjectId: string
  }) =>
    new ApiClient().post(
      `${basePath(
        params.universalCorporationId,
        params.projectId
      )}/change_sw_project`,
      {
        swProjectId: params.swProjectId,
      }
    ),
}
