import { userProfileSearch } from "./userProfileSearch"
import { internalNotification } from "./internalNotification"
import { swCorporationAccount } from "./swCorporationAccount"
import { project } from "./project"

export const user = {
  userProfileSearch,
  internalNotification,
  swCorporationAccount,
  project,
}
