import { ApiClient } from "~/clApi/main/foundation/ApiClient"

const basePath = (universalCorporationId: string) =>
  `/project/corporation_member/${universalCorporationId}/project/sw_project`

export const write = {
  createSwProject: (params: {
    universalCorporationId: string
    projectTitle: string
    swProjectId: string
  }) =>
    new ApiClient<string>().post(
      `${basePath(params.universalCorporationId)}/create`,
      {
        title: params.projectTitle,
        swProjectId: params.swProjectId,
      }
    ),
  copySwProject: (params: {
    universalCorporationId: string
    projectId: string
  }) =>
    new ApiClient<string>().post(
      `${basePath(params.universalCorporationId)}/copy`,
      {
        projectId: params.projectId,
      }
    ),
}
