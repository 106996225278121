import { Context } from "@nuxt/types"
import { makeAuthPagePath } from "~/uiLogics/auth/authExecutor"
import { clAuth } from "~/clAuth"
import { clStores } from "~/clStores"

/**
 * 企業アカウントであることが必須のページで利用されるミドルウェア。
 */
export default function ({ route, redirect }: Context) {
  if (clAuth.corporationId) {
    clStores.swCorporationAccount.actions.loadList()
    return
  }

  if (clAuth.finishedAuthProcess) {
    redirect("/projects")
  } else {
    const authPath = makeAuthPagePath({ redirectPath: route.fullPath, route })
    redirect(authPath)
  }
}
